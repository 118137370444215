import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  forgotPassword,
  forgotPasswordSelector,
  getTokenMiddleware,
  loginWithOtp,
  sendOtp,
  userLogin,
  userRegistration,
  validateUser,
  verifyOtp,
} from "../../../redux/slices/auth/authSlice";
import Card from "../../../utils/card/Card";
import Login from "./login/Login";
import SetPassword from "./login/SetPassword";
import OtpScreen from "./OTPInput/OtpScreen";
import UserTypeScreen from "./UserTypeScreen";
import ForgotPassword from "./login/ForgotPassword";
import "react-toastify/dist/ReactToastify.css";
import {
  indianMobileNumber,
  passwordValidation,
  userNameRegx,
} from "../../../utils/Regex/regex";
import {
  getTranslatedText,
  getTranslatedTextSelector,
} from "../../../redux/slices/translatedText/getTranslatedText";
import { decrypt, encrypt } from "../../encryption/Encryption";
import Modal from "../../modal/Modal";

import ProceedToSignupModal from "./ProceedToSIgnupModal";
import ServerUpgradtionModal from "../../ServerUpgradtionModal";

const Auth = () => {
  const [count, setCount] = useState(0);
  const [otp, setOtp] = useState(null);
  const [user, setUser] = useState("");
  const [showMessage, setShowMessage] = useState(true);
  const [showEnterPass, setShowEnterPass] = useState(false);
  const [callForgotPasswordApi, setCallForgotPasswordApi] = useState(false);
  const [activateUserModal, setActivateUserModal] = useState(false);
  const [proceedToSignupModal, setProceedToSignupModal] = useState(false);
  const [showServerMessage, setShowServerMessage] = useState(false);

  const [forgotPasswordCredential, setForgotPasswordCredential] = useState({
    Password: "",
    confirmPassword: "",
  });
  const [authentication, setAuthentication] = useState({
    userTypeId: "",
    userName: "",
    password: "",
    Password: "",
    confirmPassword: "",
  });
  const { userTypeId, userName, password, Password, confirmPassword } =
    authentication;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector(forgotPasswordSelector);

  const [loading, setLoading] = useState(false);

  const getTokenfterRegistration = async (userName, userTypeId, password) => {
    try {
      const result = await dispatch(
        getTokenMiddleware({ userName, userTypeId, password })
      ).unwrap();
      const { token, status, response } = result;
      const { id } = response;
      if (status === 1) {
        const userId = id;
        localStorage.setItem("id", userId);
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(response));
        setShowMessage(true);
        toast.success("Register successfully");
        setTimeout(() => {
          navigate("/user/profile");
        }, 2000);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getText();
  }, []);

  const getText = async () => {
    await dispatch(getTranslatedText(localStorage.getItem("l_id"))).unwrap();
  };
  const getTextTranslated = useSelector(getTranslatedTextSelector);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (user === "Existing") navigate("/user/dashboard");

    if (count === 0) {
      setCount(1);
    } else if (count === 1) {
      setLoading(true);
      try {
        if (callForgotPasswordApi) {
          // setCallForgotPasswordApi(false);
          handleLoginWithOtp("forgotpassword");
          return;
        } else if (!showEnterPass) {
          try {
            if (!userNameRegx.test(authentication.userName)) {
              setShowMessage(true);
              toast.warn(
                "username should contains only alphabet , underscore , @ or 10 digit mobile number"
              );
              return;
            }
            const result = await dispatch(
              validateUser({ userName, userTypeId })
            ).unwrap();

            if (!result) {
              setShowServerMessage(true);
              return;
            }

            if (
              !indianMobileNumber.test(authentication.userName) &&
              result.message === "New User"
            ) {
              setShowMessage(true);
              //toast.error("Enter the valid Username or 10 digit mobile number");
              const message = getTranslatedMessage(
                getTextTranslated?.data,
                "error_existing_username",
                "Enter the valid Username or 10 digit mobile number"
              );
              toast.error(message);
            } else if (result.isDuplicateUser === 1) {
              const { response } = result;
              const { id } = response;
              localStorage.setItem("id", JSON.stringify(id));
              localStorage.setItem("user", JSON.stringify(response));
              setShowEnterPass(true);
              setUser("Existing");
            } else if (
              result.isDuplicateUser === 0 &&
              result?.response?.status === 0
            ) {
              const { response } = result;
              const { id } = response;
              localStorage.setItem("id", JSON.stringify(id));
              localStorage.setItem("user", JSON.stringify(response));
              setActivateUserModal(true);
              setUser("Activate");
            } else {
              const userTypeId = localStorage.getItem("userTypeId");

              if (userTypeId === "9") {
                toast.error("Invalid Credentails");
              } else {
                setShowEnterPass(false);
                setUser("New");
                setProceedToSignupModal(true);
              }
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          const userData = {
            userName: JSON.parse(localStorage.getItem("user")).userName,
            password: encrypt(password),
            userTypeId: userTypeId,
          };
          if (!passwordValidation.test(password)) {
            setShowMessage(true);
            toast.warn("Password only contains alphabet");
            return;
          }
          const response = await dispatch(userLogin(userData)).unwrap();
          if (response.message === "Success") {
            setShowMessage(true);
            toast.success("Successfully login");
            const userId = response.userId;
            const token = response.token;
            localStorage.setItem("token", token);
            localStorage.setItem("id", userId);
            localStorage.setItem("user", JSON.stringify(response.userResponse));
            setTimeout(() => {
              navigate("/user/dashboard");
            }, 1000);
          } else {
            setShowMessage(true);
            toast.error("Invalid credential");
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else if (count === 2) {
      try {
        const userName = JSON.parse(localStorage.getItem("user"))?.userName
          ? JSON.parse(localStorage.getItem("user")).userName
          : authentication.userName;
        if (user === "Existing" && callForgotPasswordApi === false) {
          const UserName =
            userName?.length <= 10 ? encrypt(userName) : userName;
          const response = await dispatch(
            loginWithOtp({ UserName, otp: encrypt(otp), userType: userTypeId })
          ).unwrap();

          const { status, token, userResponse } = response;
          if (status === 1) {
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(userResponse));
            setShowMessage(true);
            toast.success("Successfully login");
            setTimeout(() => {
              navigate("/user/dashboard");
            }, 1000);
          } else {
            setShowMessage(true);
            toast.error("You entered wrong OTP");
          }
        } else if (user === "Activate") {
          const userId = JSON?.parse(localStorage?.getItem("id")).toString();
          const userName = JSON.parse(localStorage.getItem("user"))?.userName
            ? JSON.parse(localStorage.getItem("user")).userName
            : authentication.userName;
          const UserName =
            userName?.length <= 10 ? encrypt(userName) : userName;
          dispatch(
            loginWithOtp({
              UserName,
              otp: encrypt(otp),
              userType: userTypeId,
            })
          ).then((result) => {
            const { status, token, userResponse } = result.payload;

            if (status === 1) {
              localStorage.setItem("token", token);
              localStorage.setItem("user", JSON.stringify(userResponse));

              setShowMessage(true);
              toast.success("Successfully login");

              setTimeout(() => {
                navigate("/user/dashboard");
              }, 1000);
            } else {
              setShowMessage(true);
              toast.error("You entered wrong OTP");
            }
          });
        } else {
          const userName = JSON.parse(localStorage.getItem("user"))?.mobileNo
            ? JSON.parse(localStorage.getItem("user")).mobileNo
            : authentication.userName;
          const UserName =
            userName?.length <= 10 ? userName : decrypt(userName);
          const response = await dispatch(
            verifyOtp({ UserName, otp })
          ).unwrap();

          if (response.message === "Invalid OTP") {
            setShowMessage(true);
            toast.error("Invalid OTP");
          } else if (response.message === "Error! OTP Expire") {
            setShowMessage(true);
            toast.error("Error! OTP Expire");
          } else if (response.status === 1 && user === "New") {
            setShowMessage(true);
            toast.success("OTP is verified successfully");
            setTimeout(() => {
              setCount(3);
            }, 1000);
          } else if (response.status === 1) {
            setShowMessage(true);
            toast.success("OTP is verified successfully");
            setTimeout(() => {
              setCount(4);
            }, 1000);
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else if (count === 3) {
      if (Password !== confirmPassword) {
        toast.error("Password and confirm password should be same");
      } else {
        try {
          if (!userNameRegx.test(authentication.userName)) {
            if (!indianMobileNumber.test(authentication.userName)) {
              setShowMessage(true);
              toast.warn(
                "username should contains only alphabet , underscore , @ or 10 digit mobile number"
              );

              return;
            }
          } else if (
            !passwordValidation.test(Password) ||
            !passwordValidation.test(confirmPassword)
          ) {
            setShowMessage(true);
            toast.warn("Password only contains alphabet");

            return;
          }
          const MobileNo = localStorage.getItem("mobileno");
          dispatch(
            userRegistration({ userName, userTypeId, Password, MobileNo })
          ).then((response) => {
            const { status } = response.payload;
            const { userName, userTypeId } = response.payload.response;
            const passWord = response.meta.arg.Password;

            if (status === 1) {
              const userData = {
                userName: userName,
                password: encrypt(passWord),
                userTypeId: userTypeId,
              };

              dispatch(userLogin(userData)).then((result) => {
                const { status, token, userResponse, userId } = result.payload;

                if (status === 1) {
                  localStorage.setItem("id", userId);
                  localStorage.setItem("token", token);
                  localStorage.setItem("user", JSON.stringify(userResponse));
                  setShowMessage(true);
                  toast.success("Register successfully");
                  setTimeout(() => {
                    navigate("/user/profile");
                  }, 2000);
                }
              });
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
    } else if (count === 4) {
      setCallForgotPasswordApi(false);
      const { Password, confirmPassword } = forgotPasswordCredential;
      if (
        !passwordValidation.test(Password) ||
        !passwordValidation.test(confirmPassword)
      ) {
        setShowMessage(true);
        toast.warn("Password only contains alphabet");

        return;
      } else if (Password !== confirmPassword) {
        setShowMessage(true);
        toast.warn("Password and confirm password should be same");

        return;
      } else {
        dispatch(forgotPassword({ Password }))
          .then((res) => {
            if (res.payload.result === true) {
              setShowMessage(true);
              toast.success("Password updated successfully");
              setAuthentication({
                ...authentication,
                password: "",
                confirmPassword: "",
              });
              setTimeout(() => {
                setCount(1);
              }, 1000);
            } else {
              setShowMessage(true);
              toast.warn("Old password and new password can not be same");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      setCount(0);
    }
  };

  const handleChange = (e, screen) => {
    if (screen === "userType") {
      setAuthentication((prev) => ({ ...prev, userTypeId: e.target.id }));
      localStorage.setItem("userTypeId", e.target.id);
    } else if (screen === "login") {
      setAuthentication((prev) => ({ ...prev, userName: e.target.value }));
    } else if (screen === "loginPass") {
      setAuthentication((prev) => ({ ...prev, password: e.target.value }));
    } else if (screen === "setPassword") {
      setAuthentication({ ...authentication, [e.target.name]: e.target.value });
    } else {
      setForgotPasswordCredential({
        ...forgotPasswordCredential,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleLoginWithOtp = async (screen) => {
    const mobileno = JSON?.parse(localStorage?.getItem("user"))?.mobileNo
      ? JSON?.parse(localStorage?.getItem("user"))?.mobileNo
      : JSON?.parse(localStorage?.getItem("user"))?.userName;

    setActivateUserModal(false);

    localStorage.setItem("mobileno", mobileno ? mobileno : userName);
    const mobile_no = mobileno ? decrypt(mobileno) : mobileno;

    if (
      !indianMobileNumber.test(mobile_no ? mobile_no : userName) &&
      screen !== "New"
    ) {
      setShowMessage(true);
      toast.error("Please enter a valid number");
      return;
    } else if (screen) {
      localStorage.setItem("mobileno", authentication.userName);
    }
    const response = await dispatch(
      sendOtp(mobile_no ? mobile_no : authentication.userName)
    ).unwrap();

    if (response.message === "Error! Invalid Mobile No") {
      setShowMessage(true);
      toast.error("Please enter valid mobile number or username");
    } else if (response.status === 1) {
      setShowMessage(true);
      toast.success("OTP is sent your mobile number successfully");
      setTimeout(() => {
        setCount(2);
      }, 2000);
    } else if (response.status === 0) {
      setShowMessage(true);
      toast.error("Unable to sent otp");
      setTimeout(() => {
        setCount(1);
      }, 2000);
    }
  };

  const getTranslatedMessage = (data, pageKey, fallback) => {
    const item = data?.filter((item) => item.pageKey === pageKey)[0];
    return item?.keyValue || fallback;
  };

  return (
    <>
      {/* <Encryption /> */}
      <div className="bg-gradient-to-b from-[#002EFF2B] to-[#0DE9FF2B] my-[75px] md:my-[200px] lg:my-[75px] lg:mx-[20px] lg:mx-[150px] xl:mx-[300px] xl:my-[50px] p-4 rounded-md drop-shadow-[0_04px_20px_#00000021]">
        <div className="grid grid-cols-1 md:grid-cols-3 bg-[#FDFDFD] rounded-md p-4 ">
          <div className="col-span-1">
            <Card height={"[50px]"} color="#101942" className="min-h-full" />
          </div>
          <div className="col-span-2 my-4 ">
            <form onSubmit={handleSubmit} className="">
              {count === 0 ? (
                <UserTypeScreen
                  handleChange={handleChange}
                  getTextTranslated={getTextTranslated}
                />
              ) : count === 1 ? (
                <Login
                  password={password}
                  name={userName}
                  handleChange={handleChange}
                  showEnterPass={showEnterPass}
                  getTextTranslated={getTextTranslated}
                />
              ) : count === 2 ? (
                <OtpScreen
                  heading={
                    getTextTranslated?.data?.length > 0
                      ? getTextTranslated?.data?.filter(
                          (item) => item.pageKey === "otp_verification"
                        )[0].keyValue
                      : "OTP Verification"
                  }
                  setOtp={setOtp}
                  handleLoginWithOtp={handleLoginWithOtp}
                  mobile={userName}
                  getTextTranslated={getTextTranslated}
                />
              ) : count === 3 && user === "New" ? (
                <SetPassword
                  name={authentication.userName}
                  handleChange={handleChange}
                  getTextTranslated={getTextTranslated}
                />
              ) : count === 4 ? (
                <ForgotPassword
                  handleChange={handleChange}
                  getTextTranslated={getTextTranslated}
                />
              ) : (
                <h1>End</h1>
              )}
              {showEnterPass && count !== 2 && (
                <p
                  className={`${
                    userTypeId === "9" ? "hidden" : "block"
                  } text-[#101942] cursor-pointer mb-[15px] lg:w-[55%] w-[80%] m-auto text-left text-[14px]"`}
                  onClick={() => {
                    setShowEnterPass(false);
                    setCallForgotPasswordApi(true);
                  }}
                >
                  {getTextTranslated?.data?.length > 0
                    ? getTextTranslated?.data?.filter(
                        (item) => item.pageKey === "forget_pswd"
                      )[0].keyValue
                      ? getTextTranslated?.data?.filter(
                          (item) => item.pageKey === "forget_pswd"
                        )[0].keyValue
                      : "Forgot Password"
                    : "Forgot Password"}{" "}
                  ?{" "}
                  <span className="text-[#40b2d4] font-semibold">
                    {getTextTranslated?.data?.length > 0
                      ? getTextTranslated?.data?.filter(
                          (item) => item.pageKey === "click_here"
                        )[0].keyValue
                        ? getTextTranslated?.data?.filter(
                            (item) => item.pageKey === "click_here"
                          )[0].keyValue
                        : "Click Here"
                      : "Click Here"}
                  </span>
                </p>
              )}
              <button
                type="submit"
                className={loading ? "bg-[#3a3e58] text-[#fff] py-3 md:py-2 px-24 rounded-md m-auto flex justify-center relative mt-2 lg:w-[55%] w-[80%]" 
                  : "bg-[#101942] text-[#fff] py-3 md:py-2 px-24 rounded-md m-auto flex justify-center relative mt-2 lg:w-[55%] w-[80%]"}
                disabled={loading} // Disable button when loading
              >
                {loading ? (
                  <>
                    <div className="flex items-center space-x-2">
                      <div className="spinner-border animate-spin inline-block w-4 h-4 border-4 rounded-full"></div>
                      <span>Loading...</span>
                    </div>
                  </>
                ) : getTextTranslated?.data?.length > 0 ? (
                  getTextTranslated?.data?.filter(
                    (item) => item.pageKey === "btn_proceed"
                  )[0].keyValue
                ) : (
                  "Proceed"
                )}
                {/* <ReactIcons.AiFillRightCircle className="absolute right-3 top-[13px] text-[#586084] text-xl" /> */}
                <span className="bg-[#586084] p-[8px] rounded-[100%] absolute right-3 top-[12px] md:top-[8px]">
                  <img src="../right-arrow.png" className="w-[8px] h-[10px]" />
                </span>
              </button>
              {showEnterPass && count !== 2 && (
                <div
                  className={`${
                    userTypeId === "9" ? "hidden" : "block"
                  } text-[#4182FF] font-semibold cursor-pointer mb-2 mt-2 text-left lg:w-[55%] w-[80%] m-auto `}
                  onClick={handleLoginWithOtp}
                >
                  {getTextTranslated?.data?.length > 0
                    ? getTextTranslated?.data?.filter(
                        (item) => item.pageKey === "key_login_with_otp"
                      )[0].keyValue
                    : " Login with OTP "}
                </div>
              )}
            </form>
            <a
              href="https://projectinclusion.in/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              {count === 0 && (
                <p className="mt-3 cursor-pointer text-[#D3D3D3]">
                  {getTextTranslated?.data?.length > 0
                    ? getTextTranslated?.data?.filter(
                        (item) => item?.pageKey === "txt_label_privacy"
                      )[0]?.keyValue
                      ? getTextTranslated?.data?.filter(
                          (item) => item?.pageKey === "txt_label_privacy"
                        )[0]?.keyValue
                      : "Privacy Policy"
                    : "Privacy Policy"}
                </p>
              )}
            </a>
          </div>
        </div>
      </div>
      {showMessage && (
        <ToastContainer
          pauseOnHover={true}
          autoClose={2000}
          position="top-right"
        />
      )}
      {activateUserModal && (
        <Modal visible={activateUserModal}>
          <div>
            {/* Modal Content */}
            <div className=" w-12/12 m-auto mx-[15px] ">
              <div className=""></div>
              <div className="bg-[#fff] px-6 py-6 rounded-[30px] flex flex-col justify-center items-center">
                <h5 className="font-semibold text-[20px] my-2">
                  Are you sure you want to recover your account?
                </h5>
                <p className="w-[80%]">You’re requesting to recover account.</p>
                <div className="flex justify-around w-[100%]">
                  <button
                    // onClick={() => setShowQna(false)}
                    onClick={() => {
                      localStorage.clear();

                      navigate("/user/language");
                      setActivateUserModal(false);
                    }}
                    className="bg-gradient-to-r from-[#E7EAFF] to-[#E7EAFF] text-[#3C57AD] px-[40px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[40px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                    onClick={handleLoginWithOtp}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showServerMessage && (
        <ServerUpgradtionModal
          show={showServerMessage}
          setShow={setShowServerMessage}
          apiCall={handleSubmit}
        />
      )}
      <ProceedToSignupModal
        authentication={authentication}
        show={proceedToSignupModal}
        setShow={setProceedToSignupModal}
        handleLoginWithOtp={handleLoginWithOtp}
      />
    </>
  );
};

export default Auth;
